/* eslint-disable camelcase */
import React, { useEffect, useState, createContext, useContext, useMemo, SetStateAction, Dispatch } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import type { User as Auth0User } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { RECRUITING_SCHEMAS } from 'constants/cubeNames';
import { COMPANY_KEY, FISCAL_YEAR_START_MONTH_KEY, ORG_ID_KEY } from 'constants/storageKeyVal';

import registerChartTheme from 'utils/registerChartTheme';
import {
  isCpoHqOrganization,
  userHasFieldVisibilityRestrictions,
  userHasPopulationVisibilityRestrictions,
} from 'utils/userUtils';

import permissionGroupsApi from 'api/permissionGroupsApi';
import userApi, { UserAnalyticsProfile } from 'api/userApi';
import { AUTH0_AUDIENCE } from 'config';

const IS_FIRST_LOGIN_KEY = 'https://knoetic.com/is_first_login';

export interface UserInfo extends Auth0User {
  // TODO: Add knoetic app metadata, organization and roles if needed
  name: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  permissions: string[];
  picture: string;
  sub: string;
}

export interface UserContextInterface {
  user: UserInfo;
  setUser: Dispatch<SetStateAction<UserInfo | Auth0User | undefined>>;
  analyticsProfile: UserAnalyticsProfile;
  setAnalyticsProfile: Dispatch<SetStateAction<UserAnalyticsProfile>>;
  fiscalYearStartMonth: number;
  initialized: boolean;
  loading: boolean;
  error?: Error | unknown | null;
  hasFieldVisibilityRestrictions: boolean;
  hasPopulationVisibilityRestrictions: boolean;
  hasAtsRestrictions: boolean;
  isFirstLogin: boolean;
}

const defaultAnalyticsProfile: UserAnalyticsProfile = {
  account_type: '',
  can_share_report: false,
  company: '',
  date_joined: '',
  email: '',
  field_visibility_type: '',
  first_name: '',
  full_name: '',
  has_access_to_cpohub: false,
  has_access_to_cpohub_network: false,
  has_analytics_enabled: false,
  has_complete_profile: false,
  has_hard_disable_feature_flag: false,
  has_org_chart_freemium_access: false,
  hris_employee_id: null,
  id: 1,
  is_admin: false,
  is_cpo: false,
  is_guest: false,
  last_name: '',
  population_visibility_type: '',
  profile_picture_url: '',
  user_hash: '',
  visibility_type: '',
  front_chat_hash: '',
  company_root_nodes: [],
  company_json: {
    configurations: {
      color_schemes: [],
      repeat_colors: false,
    },
    fiscal_year_start_month: 1,
  },
};

export const UserContext = createContext<UserContextInterface>({
  user: {
    name: '',
    email: '',
    email_verified: false,
    family_name: '',
    given_name: '',
    permissions: [],
    picture: '',
    sub: '',
  },
  setUser: () => null,
  analyticsProfile: defaultAnalyticsProfile,
  setAnalyticsProfile: () => undefined,
  initialized: false,
  loading: false,
  error: undefined,
  fiscalYearStartMonth: 1,
  hasFieldVisibilityRestrictions: false,
  hasPopulationVisibilityRestrictions: false,
  hasAtsRestrictions: false,
  isFirstLogin: false,
});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { getIdTokenClaims, getAccessTokenSilently, user: auth0User } = useAuth0();

  const [user, setUser] = useState<UserInfo | Auth0User | undefined>(auth0User);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<Error | unknown | null>(null);
  const [analyticsProfile, setAnalyticsProfile] = useState<UserAnalyticsProfile>(defaultAnalyticsProfile);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [hasFieldVisibilityRestrictions, setHasFieldVisibilityRestrictions] = useState(true);
  const [hasPopulationVisibilityRestrictions, setHasPopulationVisibilityRestrictions] = useState(true);
  const [hasAtsRestrictions, setHasAtsRestrictions] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const ldClient = useLDClient();

  useEffect(() => {
    if (initialized || loading) return;

    const fetchAnalyticsProfile = async () => {
      setLoading(true);

      const claims = await getIdTokenClaims();
      localStorage.setItem(ORG_ID_KEY, claims?.org_id as string); // Keep so we can reuse between browser sessions

      const accessTokenVal = await getAccessTokenSilently({
        authorizationParams: {
          redirect_uri: window.location.origin,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          audience: AUTH0_AUDIENCE,
          organization: localStorage.getItem(ORG_ID_KEY),
        },
      });
      const decodedAccessToken: UserInfo = jwtDecode(accessTokenVal);
      setPermissions(decodedAccessToken.permissions);

      // TODO: Should get "analyticsProfileData" from config-service instead of Optica
      const analyticsProfileData = await userApi.getUser();
      setAnalyticsProfile(analyticsProfileData);
      sessionStorage.setItem(COMPANY_KEY, analyticsProfileData.company);
      sessionStorage.setItem(
        FISCAL_YEAR_START_MONTH_KEY,
        analyticsProfileData.company_json?.fiscal_year_start_month?.toString() || '',
      );
      registerChartTheme(
        analyticsProfileData.company_json.configurations?.color_schemes || [],
        analyticsProfileData.company_json.configurations?.repeat_colors || false,
      );

      const _userHasFieldVisibilityRestrictions = userHasFieldVisibilityRestrictions(analyticsProfileData);
      const _userHasPopulationVisibilityrestrictions = userHasPopulationVisibilityRestrictions(analyticsProfileData);

      if (_userHasFieldVisibilityRestrictions) {
        const groups = await permissionGroupsApi.getActivePermissionGroups();
        const userEmail = analyticsProfileData?.email;
        const userPermissionGroup = groups?.find((group) =>
          group.users?.find(({ userId }) => userId === userEmail || userId === auth0User?.sub),
        );

        if (userPermissionGroup) {
          if (
            userPermissionGroup?.missingFields?.some(({ field }) => field && RECRUITING_SCHEMAS.includes(field.schema))
          ) {
            setHasAtsRestrictions(true);
          }

          // TODO: The Frontend should not be responsible for updating the userId in the Permission Group. Move this to the Backend.
          // During User creation, a User invitation will be created in Auth0 and they are added into a Permission Group WITHOUT a userId, the only identifier we have is their email.
          // Upon their first login, we need to update their userId in the Permission Group to their Auth0 userId which is created after accepting the invite.
          const permissionGroupMatchedByEmail = groups?.find((group) =>
            group.users?.find(({ userId }) => userId === userEmail),
          );
          if (claims?.[IS_FIRST_LOGIN_KEY] && auth0User?.sub && permissionGroupMatchedByEmail) {
            await permissionGroupsApi.updateUserInPermissionGroup(userEmail, userPermissionGroup.id);
          }
        }

        setIsFirstLogin(!!claims?.[IS_FIRST_LOGIN_KEY]);
      }

      setHasPopulationVisibilityRestrictions(_userHasPopulationVisibilityrestrictions);
      setHasFieldVisibilityRestrictions(_userHasFieldVisibilityRestrictions);

      // Initialize feature flag with LaunchDarkly
      if (ldClient) {
        await ldClient.identify({
          key: user?.sub,
          name: user?.name,
          email: user?.email,
          custom: { company: analyticsProfileData.company, isCpohqOrg: isCpoHqOrganization(user as UserInfo) },
        });
      } else {
        throw new Error('Unable to initialize LaunchDarkly client in UserProvider. ldClient is undefined.');
      }
    };

    fetchAnalyticsProfile()
      .catch((nextError: Error | unknown) => {
        Sentry.captureException(nextError);
        setError(nextError);
      })
      .finally(() => {
        setInitialized(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0User]);

  // avoid generating duplication
  const userWithAuth = useMemo(() => {
    return {
      ...user,
      permissions,
      // given/family name may not always be there. Evaluate them from `.name` instead
      ...(auth0User && {
        given_name: auth0User.given_name || auth0User.name?.split(' ')[0],
        family_name: auth0User.family_name || auth0User.name?.split(' ').slice(1).join(' '),
      }),
    };
  }, [user, auth0User, permissions]);

  const value = useMemo(() => {
    return {
      user: userWithAuth as UserInfo,
      setUser,
      analyticsProfile,
      setAnalyticsProfile,
      fiscalYearStartMonth: analyticsProfile?.company_json?.fiscal_year_start_month,
      initialized,
      loading,
      error,
      hasFieldVisibilityRestrictions,
      hasPopulationVisibilityRestrictions,
      hasAtsRestrictions,
      isFirstLogin,
    };
  }, [
    userWithAuth,
    analyticsProfile,
    error,
    hasFieldVisibilityRestrictions,
    hasPopulationVisibilityRestrictions,
    initialized,
    loading,
    hasAtsRestrictions,
    isFirstLogin,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
